
import React, { useEffect, useState } from "react";
import "./ViewProductList.scss";
import { assets } from "../../assets/assets";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Link, useNavigate, useLocation } from "react-router-dom";
import products from "../ProductCatogory/Product.json";

const ViewProductList = ({category}) => {

  const navigate = useNavigate();

  const filteredProducts = products.filter((product) => product.category === category);

  if (filteredProducts.length < 1) {

    navigate("/single", { state: { product: filteredProducts[0], } });
  }

  
  const handleBack = () => {
    navigate("/productCatogory")
  }


  


  return (
    <div className="viewproductlist">
      <h5>
        Product Category
        <span onClick={handleBack} >
          <MdOutlineArrowForwardIos /> { category }
        </span>
      </h5>
      <div className="sub_productlist">
        {filteredProducts.map((product) => (
          <div className="sub_product_item" key={product.id}>
            <div className="item_contents">
              <h6>{product.name}</h6>
              <p>
                
              <div dangerouslySetInnerHTML={{ __html: product.shortDesc }} />

              </p>
              <Link to="/single" state={{  product: product, categoryProducts: filteredProducts }}>
                <button>
                  Read More <MdOutlineArrowForwardIos />
                </button>
              </Link>
            </div>
            <div className="item_image">
              <img src={assets[product.img]} alt=""/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default ViewProductList;