import React, { useEffect, useState } from "react";
import { assets } from "../../assets/assets";
import Aos from "aos";

const Services = () => {
  const [breakpoint, setBreakPoint] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setBreakPoint(window.innerWidth);

    window.addEventListener("resize", handleResize);

    Aos.init({
      duration: 800,
      easing: "ease-in",
      once: true,
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const isMobile = breakpoint >= 480 && breakpoint <= 1200;

  return (
    <>
      {isMobile ? (
        <>
          <div className="about">
            <h6 data-aos="fade-up" >Specializations</h6>
            <div className="about_list">
              <div className="about_item_mobile">
                <div data-aos="fade-up" className="image_header_container">
                  <div className="rubber_img">
                    <img src={assets.CustomRubberMoldingImg} alt="" />
                  </div>

                  <h5>Custom Rubber Molding</h5>
                </div>

                <div data-aos="fade-down" className="rubber_content">
                  <p>
                    Custom Rubber Molding Industrial Elastomers excels in the
                    manufacturing of high-quality molded rubber parts tailored
                    to meet specific industry needs. Our state-of-the-art
                    molding techniques ensure precision and durability, making
                    our components ideal for a variety of applications in the
                    automotive, industrial, and medical sectors.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <div className="about ">
            <div
              className="about_list"
              // style={{flexDirection:"column"}}
            >
              <div className="about_item_mobile childsecond">

                <div data-aos="fade-up" className="image_header_container">
                  <div className="rubber_img">
                    <img src={assets.CustomFormulationsImg} alt="" />
                  </div>

                  <h5>Custom Formulations</h5>
                </div>

                <div data-aos="fade-down" className="rubber_content">
                  <p>
                    At Industrial Elastomers, we understand the importance of
                    performance under specific conditions. We offer custom
                    formulations of polymers and their blends, including Natural
                    Rubber, SBR, NBR, PBR, Neoprene, EPDM, Polyurethane, Viton,
                    and Silicone. Our compounds are designed to withstand wide
                    temperature and pressure ranges, meeting stringent customer
                    specifications and international standards.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <div className="about">
            <div
              className="about_list"
              // style={{flexDirection:"column"}}
            >
              <div className="about_item_mobile">
                <div data-aos="fade-up" className="image_header_container">
                  <div className="rubber_img">
                    <img src={assets.IndustryApplicationsImg} alt="" />
                  </div>

                  <h5>Industry Applications</h5>
                </div>

                <div data-aos="fade-down" className="rubber_content">
                  <p>
                    Our rubber components are engineered to meet the critical
                    needs of diverse industries. We serve the automotive, oil
                    field, chemical and petrochemical, electronic,
                    pharmaceutical, defense, space, and marine sectors, ensuring
                    our products perform reliably in the most demanding
                    applications.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <div className="about ">
            <div
              className="about_list"
              // style={{flexDirection:"column"}}
            >
              <div className="about_item_mobile ">
                <div data-aos="fade-up" className="image_header_container">
                  <div className="rubber_img">
                    <img
                      src={assets.ClientSupporrIndCustomSolutionsImg}
                      alt=""
                    />
                  </div>

                  <h5>Client Support and Custom Solutions</h5>
                </div>

                <div data-aos="fade-down"  className="rubber_content">
                  <p>
                    We pride ourselves on our ability to deliver custom
                    solutions that meet our clients' exact specifications. Our
                    team works closely with multinational companies, government
                    organizations, and various engineering establishments to
                    develop products that are both innovative and
                    cost-effective.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="about">
            <h6 data-aos="fade-down">Specializations</h6>
            <div className="about_list">
              <div className="about_item">
                <div data-aos="fade-down" className="rubber_img">
                  <img src={assets.CustomRubberMoldingImg} alt="" />
                </div>
                <div data-aos="fade-up" className="rubber_content">
                  <h5>Custom Rubber Molding</h5>
                  <p>
                    Custom Rubber Molding Industrial Elastomers excels in the
                    manufacturing of high-quality molded rubber parts tailored
                    to meet specific industry needs. Our state-of-the-art
                    molding techniques ensure precision and durability, making
                    our components ideal for a variety of applications in the
                    automotive, industrial, and medical sectors.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          {/* <div className="about">
            <div className="about_list">
              <div className="about_item childsecond">
                <div data-aos="fade-down" className="rubber_content">
                  <h5>Rubber-to-Metal Bonded Components</h5>
                  <p>
                    We specialize in the production of rubber-to-metal bonded
                    components, combining the resilience of rubber with the
                    strength of metal. This service is crucial for applications
                    requiring a strong, yet flexible bond, providing superior
                    performance in demanding environments.
                  </p>
                </div>

                <div data-aos="fade-up" className="rubber_img">
                  <img src={assets.RubbertoMetalBondedComponentsImg} alt="" />
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div> */}

          {/* <div className="about">
            <div className="about_list">
              <div className="about_item">
                <div data-aos="fade-down" className="rubber_img">
                  <img src={assets.ExtrudedRubberComponentsIMg} alt="" />
                </div>
                <div data-aos="fade-up" className="rubber_content">
                  <h5>Extruded Rubber Components</h5>
                  <p>
                    Our expertise in extruding rubber allows us to create
                    complex profiles and shapes with high precision. These
                    extruded components are used across various industries,
                    ensuring reliable performance and longevity.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div> */}

          <div className="about">
            <div className="about_list">
              <div className="about_item childsecond">
                <div data-aos="fade-down" className="rubber_content">
                  <h5>Custom Formulations</h5>
                  <p>
                    At Industrial Elastomers, we understand the importance of
                    performance under specific conditions. We offer custom
                    formulations of polymers and their blends, including Natural
                    Rubber, SBR, NBR, PBR, Neoprene, EPDM, Polyurethane, Viton,
                    and Silicone. Our compounds are designed to withstand wide
                    temperature and pressure ranges, meeting stringent customer
                    specifications and international standards.
                  </p>
                </div>

                <div data-aos="fade-up" className="rubber_img">
                  <img src={assets.CustomFormulationsImg} alt="" />
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <div className="about">
            <div className="about_list">
              <div className="about_item">
                <div data-aos="fade-down" className="rubber_img">
                  <img src={assets.IndustryApplicationsImg} alt="" />
                </div>
                <div data-aos="fade-up" className="rubber_content">
                  <h5>Industry Applications</h5>
                  <p>
                    Our rubber components are engineered to meet the critical
                    needs of diverse industries. We serve the automotive, oil
                    field, chemical and petrochemical, electronic,
                    pharmaceutical, defense, space, and marine sectors, ensuring
                    our products perform reliably in the most demanding
                    applications.
                  </p>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <div className="about">
            <div className="about_list">
              <div className="about_item childsecond">
                <div data-aos="fade-down" className="rubber_content">
                  <h5>Client Support and Custom Solutions</h5>
                  <p>
                    We pride ourselves on our ability to deliver custom
                    solutions that meet our clients' exact specifications. Our
                    team works closely with multinational companies, government
                    organizations, and various engineering establishments to
                    develop products that are both innovative and
                    cost-effective.
                  </p>
                </div>

                <div data-aos="fade-up" className="rubber_img">
                  <img src={assets.ClientSupporrIndCustomSolutionsImg} alt="" />
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Services;
