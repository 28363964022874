import React, { useEffect, useState } from "react";
import "./CompanyDetails.scss";
import { assets } from "../../assets/assets";
import AOS from "aos";
import "aos/dist/aos.css";

const CompanyDetails = () => {

  const [breakpoint, setBreakPoint] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setBreakPoint(window.innerWidth);

    window.addEventListener("resize", handleResize);

    AOS.init({
      duration: 800, // Duration of animation
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once or every time you scroll up/down
    });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {


  //   AOS.init({
  //     duration: 800, // Duration of animation
  //     easing: "ease-in-out", // Easing function
  //     once: true, // Whether animation should happen only once or every time you scroll up/down
  //   });
  // }, []);


  const isMobile = breakpoint >= 480 && breakpoint <= 1200;

  return (
    <>

    { isMobile ? (
      <div className="copany_deails_container">
      <div className="copany_deails_container_main isMobContainer" data-aos="fade-up">

        <div className="image_and_head_container isMobImgNhead">

        <div className="copany_deails_img_div">
          <img src={assets.whyChoseCustomersImg} alt="" />
        </div>

        <h5 className="mobHead">Why Choose Industrial Elastomers?</h5>

        </div>
    


        <div className="copany_deails_points">
       
          <ul class="custom-list">
            <li>
              Over two decades of expertise in rubber component manufacturing{" "}
            </li>
            <li>
              A comprehensive range of services tailored to diverse industry
              needs.
            </li>
            <li>
              High-quality, custom-formulated rubber products designed for
              critical applications
            </li>
            <li>A commitment to meeting stringent quality and </li>
            <li>performance standards</li>
            <li>
              Proven track record of serving prestigious clients across the
              globe.{" "}
            </li>
          </ul>
        </div>




      </div>
    </div>

      ) : (
        <div className="copany_deails_container">
        <div className="copany_deails_container_main" data-aos="fade-up">
  
          <div className="copany_deails_points">
            <h5>Why Choose Industrial Elastomers?</h5>
  
            <ul class="custom-list">
              <li>
                Over two decades of expertise in rubber component manufacturing{" "}
              </li>
              <li>
                A comprehensive range of services tailored to diverse industry
                needs.
              </li>
              <li>
                High-quality, custom-formulated rubber products designed for
                critical applications
              </li>
              <li>A commitment to meeting stringent quality and </li>
              <li>performance standards</li>
              <li>
                Proven track record of serving prestigious clients across the
                globe.{" "}
              </li>
            </ul>
          </div>
  
          <div className="copany_deails_img_div">
            <img src={assets.whyChoseCustomersImg} alt="" />
          </div>
  
  
  
        </div>
      </div>
      )
     }

</>
   
  );
};

export default CompanyDetails;
