import React, { useEffect, useState } from "react";
import "./About.scss";
import { assets } from "../../assets/assets";
import AOS from "aos";
import "aos/dist/aos.css";
import CompanyDetails from "../CompanyDetails/CompanyDetails";

const About = () => {
  const [breakpoint, setBreakPoint] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setBreakPoint(window.innerWidth);

    window.addEventListener("resize", handleResize);

    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: false,
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = breakpoint >= 480 && breakpoint <= 1200;

  return (
    <>
      {isMobile ? (
        <>
          <div className="about">
            <h6 data-aos="fade-up">Who we are</h6>
            <div className="about_list">
              <div className="about_item_mobile">
                <div data-aos="fade-up" className="image_header_container">
                  <div className="rubber_img">
                    <img src={assets.Tyre} alt="" />
                  </div>

                  <h5>
                    Pioneering Rubber Solutions <br /> Since 1997
                  </h5>
                </div>

                <div data-aos="fade-down" className="rubber_content">
                  <ul>
                    <li>
                      <span>Established in 1997:</span> Based in Alwaye, Kerala,
                      Industrial Elastomers produces high-quality rubber
                      components such as molded, rubber to metal bonded and extruded parts.
                    </li>
                    <li>
                      <span>Diverse Applications:</span> Serving critical needs
                      in the industrial, automotive, and medical sectors.
                    </li>
                    <li>
                      <span>Experienced Management:</span> Led by a dynamic,
                      qualified team, ensuring recognized quality and
                      reliability.
                    </li>
                    <li>
                      <span>Notable Clients:</span> Includes multinational
                      companies, government organizations, and engineering
                      firms, with quality assurance backed by scientific
                      testing.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="quality"></div>
            </div>
          </div>


          <CompanyDetails />

          <div className="about">
            <div className="about_list">
              <div className="about_item_mobile">
                <div data-aos="fade-up" className="image_header_container">
                  <div className="rubber_img">
                    <img src={assets.Ring_img_3} alt="" />
                  </div>

                  <h5>
                    Quality Assurance Backed by <br /> Scientific Testing
                  </h5>
                </div>

                <div data-aos="fade-up" className="rubber_content">
                  <ul>
                    {/* <li>
                      <span>Advanced Testing Labs:</span> Rigorous testing of
                      all rubber products for ultimate reliability.
                    </li> */}
                    <li>
                      <span>Material Perfection:</span>Each batch is analyzed
                      for chemical and mechanical consistency.
                    </li>
                    <li>
                      <span>Proven Performance:</span> Stress-tested to endure
                      extreme conditions and pressures.
                    </li>
                    <li>
                      <span>Certified Excellence:</span> Products meet top
                      industry standards for safety and durability. Trust our
                      scientifically tested solutions for superior quality and
                      performance in every application.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <div className="about">
            <div
              className="about_list"
              // style={{flexDirection:"column"}}
            >
              <div className="about_item_mobile">
                <div data-aos="fade-down" className="image_header_container">
                  <div className="rubber_img">
                    <img src={assets.showRoom} alt="" />
                  </div>

                  <h5>
                    Innovative Rubber Blends for <br /> Superior Performance
                  </h5>
                </div>

                <div data-aos="fade-down" className="rubber_content">
                  <ul>
                    <li>
                      <span>Built to Endure:</span> Advanced rubber blends for
                      superior durability.
                    </li>
                    <li>
                      <span>Engineered to Excel:</span> Exceptional heat and
                      chemical resistance for demanding environments.
                    </li>
                    <li>
                      <span>Custom Compounds:</span> Tailored solutions for
                      aerospace, defense, and critical industries.
                    </li>
                    <li>
                      <span>Guaranteed Reliability:</span> Performance you can
                      trust in the most challenging conditions.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>
        </>
      ) : (
        // ------------------------------------------------------------------------------------------------------- is small mobile

        <>
          <div className="about">
            <h6 data-aos="fade-down">Who we are</h6>
            <div className="about_list">
              <div className="about_item">
                <div className="rubber_img" data-aos="fade-down">
                  <img src={assets.Tyre} alt="" />
                </div>
                <div className="rubber_content" data-aos="fade-up">
                  <h5>Pioneering Rubber Solutions Since 1997</h5>

                  <ul>
                    <li>
                      <span>Established in 1997:</span> Based in Alwaye, Kerala,
                      Industrial Elastomers produces high-quality rubber
                      components, such as molded, rubber to metal bonded and extruded parts.
                    </li>
                    <li>
                      <span>Diverse Applications:</span> Serving critical needs
                      in the industrial, automotive, and medical sectors.
                    </li>
                    <li>
                      <span>Experienced Management:</span> Led by a dynamic,
                      qualified team, ensuring recognized quality and
                      reliability.
                    </li>
                    <li>
                      <span>Notable Clients:</span> Includes multinational
                      companies, government organizations, and engineering
                      firms, with quality assurance backed by scientific
                      testing.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

          <CompanyDetails />


          <div className="about">
            <div className="about_list">
              <div className="about_item">
                <div className="rubber_img" data-aos="fade-down">
                  <img src={assets.showRoom} alt="" />
                </div>
                <div className="rubber_content" data-aos="fade-down">
                  <h5>Innovative Rubber Blends for Superior Performance</h5>

                  <ul>
                    <li>
                      <span>Built to Endure:</span> Advanced rubber blends for
                      superior durability.
                    </li>
                    <li>
                      <span>Engineered to Excel:</span> Exceptional heat and
                      chemical resistance for demanding environments.
                    </li>
                    <li>
                      <span>Custom Compounds:</span> Tailored solutions for
                      aerospace, defense, and critical industries.
                    </li>
                    <li>
                      <span>Guaranteed Reliability:</span> Performance you can
                      trust in the most challenging conditions.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>

           

          <div className="about ">
            <div className="about_list">
              <div className="about_item childsecond">
                <div className="rubber_content" data-aos="fade-down">
                  <h5>Quality Assurance Backed by Scientific Testing</h5>

                  <ul>
                    {/* <li>
                      <span>Advanced Testing Labs:</span> Rigorous testing of
                      all rubber products for ultimate reliability.
                    </li> */}
                    <li>
                      <span>Material Perfection:</span>Each batch is analyzed
                      for chemical and mechanical consistency.
                    </li>
                    <li>
                      <span>Proven Performance:</span> Stress-tested to endure
                      extreme conditions and pressures.
                    </li>
                    <li>
                      <span>Certified Excellence:</span> Products meet top
                      industry standards for safety and durability. Trust our
                      scientifically tested solutions for superior quality and
                      performance in every application.
                    </li>
                  </ul>
                </div>

                <div className="rubber_img" data-aos="fade-up">
                  <img src={assets.Ring_img_3} alt="" />
                </div>
              </div>
              <div className="quality"></div>
            </div>
          </div>


        </>
      )}
    </>
  );
};

export default About;
