import React from "react";
import "./Spotlight.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vedio_Rubber from "../../assets/images/vedioooo.mp4";

const Spotlight = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 6000,
    fade: false,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings}>
      <div className="spotlight">
        <div className="spotlight_container">
          <div className="content  slideThree">
            {/* <h4>We Mould Your Needs in Rubber</h4> */}
            <h4>
              Pioneering Space Exploration: Supplied High-Performance Rubber
              Products for Chandrayaan-3 mission.
            </h4>
          </div>
        </div>
      </div>

      <div className="spotlight slide2">
        <div className="spotlight_container">
          <div className="content  slideTwo ">
            {/* <h4>We Mould Your Needs in Rubber</h4> */}
            <h4>Manufacturers of Aerospace-Grade Rubber Products.</h4>
          </div>
        </div>
      </div>

      <div className="slid3">
        <video autoPlay loop muted playsInline className="background_video">
          <source src={Vedio_Rubber} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video_overlay"></div> {/* Overlay */}
        <div className="spotlight_container_three  ">
          <div className="content">
            <h4>We Mould Your Needs in Rubber</h4>
            <p>
              Empowering industries with bespoke rubber solutions since 1997,
              Industrial Elastomers is your trusted partner for
              precision-engineered rubber components.
            </p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Spotlight;
