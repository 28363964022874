import React, { useEffect } from "react";
import "./ProductCatogory.scss";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { assets } from "../../assets/assets";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
// import products from "../ProductCatogory/Product.json"
// import { Link } from "react-router-dom";
// import PdfViewer from "./PdfViewer ";

const ProductCatogory = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-out",
      once: false,
    });
  }, []);

  const handleViewProducts = (category) => {
    navigate("/product", { state: { category } });
  };

  return (
    <div className="prouduct_catogory_container">
      <h5 data-aos="fade-up"> Product Category</h5>

      <div className="prouduct_catogory_item_container" data-aos="fade-down">
        <div className="prouduct_catogory_item">
          <div className="prouduct_catogory_item_img">
            <img src={assets.bellows} alt="" />
          </div>

          <div className="prouduct_catogory_item_des_div">
            <h6>Automobile Components</h6>

            <button onClick={() => handleViewProducts("Automobile Components")}>
              View Products <MdOutlineArrowForwardIos />
            </button>
          </div>
        </div>

        <div className="prouduct_catogory_item">
          <div className="prouduct_catogory_item_img">
            <img src={assets.RubbertomentalBondedIMg} alt="" />
          </div>

          <div className="prouduct_catogory_item_des_div">
            <h6>Rubber to Metal Bonded Components</h6>

            <button
              onClick={() =>
                handleViewProducts("Rubber to metal bonded components")
              }
            >
              View Products <MdOutlineArrowForwardIos />
            </button>
          </div>
        </div>

        <div className="prouduct_catogory_item">
          <div className="prouduct_catogory_item_img">
            <img src={assets.ORingComponentMainIMg} alt="" />
          </div>

          <div className="prouduct_catogory_item_des_div">
            <h6>O Rings and Gaskets</h6>

            <Link to="/Oring">
              <button>
                View Products <MdOutlineArrowForwardIos />
              </button>
            </Link>
          </div>
        </div>

        <div className="prouduct_catogory_item">
          <div className="prouduct_catogory_item_img">
            <img src={assets.ExtrudedComponentsIMg} alt="" />
          </div>

          <div className="prouduct_catogory_item_des_div">
            <h6>Extruded Rubber Components</h6>

            <button
              onClick={() => handleViewProducts("Extruded Rubber Components")}
            >
              View Products <MdOutlineArrowForwardIos />
            </button>
          </div>
        </div>

        <div className="prouduct_catogory_item">
          <div className="prouduct_catogory_item_img">
            <img src={assets.pharmaMainImg} alt="" />
          </div>

          <div className="prouduct_catogory_item_des_div">
            <h6>Pharmaceutical Rubber Components</h6>

            <button
              onClick={() =>
                handleViewProducts("Pharmaceutical Rubber Components")
              }
            >
              View Products <MdOutlineArrowForwardIos />
            </button>
          </div>
        </div>

        <div className="prouduct_catogory_item">
          <div className="prouduct_catogory_item_img">
            <img src={assets.adesiveMain} alt="" />
          </div>

          <div className="prouduct_catogory_item_des_div">
            <h6>Adhesives</h6>

            <button onClick={() => handleViewProducts("Adhesives")}>
              View Products <MdOutlineArrowForwardIos />
            </button>
          </div>
        </div>
      </div>

      {/* <div className="catologue_container">
        <h4> Catalogue </h4>
        <div className="catologue_items">

        

        </div>
      </div> */}

      {/* <div className="catologue_container">
  <h4>Catalogue</h4>
  <div className="catologue_items">
    {/* <object
      data="/Brochure_Adhesive.pdf"
      type="application/pdf"
      width="100"
      height="80"
    >
      <p>PDF cannot be displayed. <a href="/Brochure_Adhesive.pdf">Download the PDF</a></p>

      <a href="/Brochure_Adhesive.pdf" download="catalogue.pdf">
      <button>Download Catalogue</button>
    </a>
      
    </object> */}

      {/* <PdfViewer file="/Brochure_Adhesive.pdf" /> */}
    </div>
  );
};

export default ProductCatogory;
