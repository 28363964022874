

import React, { useEffect, useState } from "react";
import "./Achievements.scss";
import VSSC from "../../assets/images/VSSC APRECIATION LETTER_page-0001.jpg";
import ISO from "../../assets/images/ISO 9001 2015 CERTIFICATE INDEL_page-0001.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ImageAlert from "./ImageAlert";
import { assets } from "../../assets/assets";
import { useLocation } from "react-router-dom";

const Achievements = () => {
    const [openAlert, setOpenAlert] = useState(false);
    const [selected, setSelected] = useState("");
    const location = useLocation()

    

    const handleAlert = (img) => {
        setSelected(img);
        setOpenAlert(true);
        console.log(openAlert, "opne alert l................")
    };

    useEffect( ()=> {

        window.scrollTo(0,0)

    },[])



    return (
        <>
            <Header />
            <div className="achievements">
                <h6>Achievements</h6>
                <div className="content_container">
                    <div className="img_wrapper">
                        {/* Content goes here */}
                        <img src={assets.achivementsChandrayan} alt="" />
                    </div>
                    <div className="text_wrapper">
                        <h5>A Proud Contribution to India's Space Exploration</h5>
                        <p>At Industrial Elastomers, we are honored to have played a role in one of India's most historic space missions – Chandrayaan-3...</p>
                        <p>Our advanced rubber products, designed to withstand extreme conditions, were used in critical systems during the Chandrayaan-3 mission...</p>
                        <p>We take immense pride in knowing that our expertise supported this monumental step in space exploration...</p>
                        <p><span>Innovation. Reliability. Excellence.</span> These are the values that propelled our products into space – and they continue to guide our work here on Earth.</p>
                    </div>
                </div>
                <div className="achievement_list">
                    <div onClick={() => handleAlert("img1")} className="image_certificate">
                        <img src={VSSC} alt="VSSC" />
                    </div>
                    <div onClick={() => handleAlert("img2")} className="image_certificate">
                        <img src={ISO} alt="ISO" />
                    </div>
                </div>
            </div>
            {openAlert && <ImageAlert selected={selected} setOpenAlert={setOpenAlert} />}
            <Footer />
        </>
    );
};

export default Achievements;

