import React from "react";
import "./ContactUs.scss";
import { MdOutlineLocalPhone } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";

const ContactUs = () => {
  return (
    <div className="contact_us_container">
      <div className="contact_us_container_sub_1">
        <h6>Contact Us</h6>

        <p>
          If you are looking for quality rubber components , You can submit your
          details on this section. We will connect you soon. For any other
          reason you may wish to contact us.
        </p>
        <ul className="contacts">
          {/* <li>
            <span>Phone:</span> (91) (484) 2839627, 2672654
          </li>
          <li>
            <span>Tele/Fax:</span> (91)(484) – 2672655
          </li> */}
          <li>
            <span>
              <MdOutlineLocalPhone />
            </span>{" "}
            9447024924
          </li>
          <li>
            <span>Email:</span> indel@rediffmail.com
          </li>
          <li className="social">
            <FaInstagram />
            <FaFacebook />
            <FaYoutube />
            {/* <FaLinkedin /> */}
            {/* <FaXTwitter /> */}
            <FaWhatsapp />
          </li>
        </ul>
      </div>
      <div className="contact_us_container_sub_2">
        <form action="">
          <div className="form_input_container">
            <div className="input_container">
              {/* <label htmlFor="First Name ">First Name</label> */}
              <input placeholder="First Name " type="text" />
            </div>

            <div className="input_container">
              {/* <label htmlFor="Last Name ">Last Name</label> */}
              <input placeholder="Last Name " type="text" />
            </div>

            <div className="input_container">
              {/* <label htmlFor="Email Id ">Email Id</label> */}
              <input placeholder="Email Id " type="text" />
            </div>

            <div className="input_container">
              {/* <label htmlFor="Mobile Number ">Mobile Number</label> */}
              <input placeholder="Mobile Number " type="text" />
            </div>
          </div>

          <div className="message_input_container">
            {/* <label htmlFor="">Message</label> */}
            <textarea placeholder=" Type your message here" type="text" />
          </div>

          <button>Sent</button>

        </form>
      </div>
    </div>
  );
};

export default ContactUs;
