import React, { useEffect } from 'react'
import "./Adhesive.scss"
import products from "../../ProductCatogory/Product.json";
import { assets } from "../../../assets/assets"
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';




const Adhesive = ({category}) => {

  const navigate = useNavigate();


  const handleBack = ()=> {
    navigate("/productCatogory");
  }



  const filteredProduct = products.filter((item)=> item.category === category );

  const newMap = filteredProduct.slice(0,-1)

  console.log(newMap, "new Map.......")

  const findElement = filteredProduct.find((item)=> item.categoryDesc === "Cold Vulcanising Adhesive" )




  const categorDesc = filteredProduct.length > 0 ? filteredProduct[0].categoryDesc : "" ;

  const contents =  filteredProduct.length > 0 ? filteredProduct[2] : "" ;



  console.log(contents, "contents.......")
    
  console.log(filteredProduct, "filtered product from Adhesive ...............")




  return (


    <div className='adhesive'>

<h6  onClick={handleBack} >Product Category 
         <span>  <MdOutlineArrowForwardIos /> Oring and Gaskets </span>
        </h6>


           { categorDesc && <h5> {categorDesc} </h5> } 


         

      <div  className="container_wrapper"  >


  
   
      <div className="adhesive_content_contianer">
      
      <p><span> {contents && contents.sentence_1_Head} </span>  {contents && contents.sentence_1_Desc} </p>
        <p><span>{contents && contents.sentence_2_Head} </span> {contents && contents.sentence_2_Desc} </p>
        <p><span> {contents && contents.sentence_3_Head} </span> {contents && contents.sentence_3_Desc}</p>
        <p><span> {contents && contents.sentence_4_Head} </span> {contents && contents.sentence_4_Desc}</p>


      </div>



  

      <div className="adhesive_item_container" >
        
      { newMap.map((product)=> (
        <div className="adhesive_item">
            <div className="adhesive_item_img">
              <img src= {assets[ product.img] } alt="" />
            </div>
            <div className="adhesive_item_name_container">
              <div className="item_name">
                  <p> {product.name} </p>
                  <span> { product.type } </span>
                  <span> {product.nameDesc} </span>

              </div>
              <div className="item_desc">
                <p> {product.chiledName} </p>
                <span> {product.childDesc} </span>
              </div>
            </div>
                

          
        </div>

))}


      </div>

     

      </div>
 
     
    </div>


  )
}

export default Adhesive