import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Product from "./pages/Product";
import SingleProduct from "./pages/SingleProduct";
import SingleProductDetails from "./components/SingleProductDetails/SingleProductDetails";
import ProductCatogory from "./components/ProductCatogory/ProductCatogory";
import ViewProductList from "./components/ViewProductList/ViewProductList";
import Adhesive from "./components/ViewProductList/Adhesive/Adhesive";
import OringGasket from "./pages/OringGasket/OringGasket";
import Achievements from "./components/Achievements/Achievements";
// import LoaderPre from "./components/LoaderPre/LoaderPre";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/single" element={<SingleProduct />} />
        <Route path="/contact" element={<SingleProduct />} />
        <Route path="/singleProductDetail" element={<SingleProductDetails />} />
        <Route path="/productCatogory" element={<ProductCatogory />} />
        <Route path="/ViewProductList" element={<ViewProductList />} />
        <Route path="/adhesive" element={<Adhesive />} />
        <Route path="/Oring" element={<OringGasket />} />
        <Route path="/achievements" element={<Achievements />} />
        {/* <Route path="/ldr" element={<LoaderPre/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
