import React, { useEffect, useState } from "react";
import "./SingleProductDetails.scss";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { assets } from "../../assets/assets";
import { useLocation, useNavigate } from "react-router-dom";

const SingleProductDetails = ({ product }) => {

  const navigate = useNavigate();

  // const { state } = useLocation();
  // const product = state?.product;

  const [breakpoint, setBreakPoint] = useState(window.innerWidth);

  useEffect(() => {

    const handleResize = () => setBreakPoint(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleBack = () => {
    
    navigate(-1)
    
    
  }
  




  const isMobile = breakpoint >= 480 && breakpoint <= 1200;
 
  
  return (

    <div className="single_product_details">
      <h5>
        Product Category <MdOutlineArrowForwardIos />  <span style={{cursor:"pointer"}} onClick={handleBack} > {product.category} </span> 
        <MdOutlineArrowForwardIos />
        
        <span> {product.name} </span>
      </h5>

      { isMobile ? (
      <div className="product_review_mobile">

          <div className="product_review_header">
          <div className="product_image">
        <img src={assets[product.img]} alt="" />
          </div>

      <h4> {product.name} </h4>

          </div>
        
      
      <div className="single_product_review">
       
        <p>
          {product.description}
        </p>
      </div>
    </div>

      ) : (
        <div className="product_review">
        <div className="product_image">
          <img src={assets[product.img]} alt="" />
        </div>
        <div className="single_product_review">
          <h4>{product.name}</h4>
          <p>
          <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </p>
        </div>
      </div>

      )}

    </div>
  );
};

export default SingleProductDetails;
