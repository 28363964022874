import React from "react";
import "./ImageAlert.scss";
import VSSC from "../../assets/images/VSSC APRECIATION LETTER_page-0001.jpg";
import ISO from "../../assets/images/ISO 9001 2015 CERTIFICATE INDEL_page-0001.jpg";
import { IoClose } from "react-icons/io5";

const ImageAlert = ({ selected, setOpenAlert }) => {
  let ImgSrc;

  if (selected === "img1") {
    ImgSrc = VSSC;
  } else if (selected === "img2") {
    ImgSrc = ISO;
  }

  return (
    <div className="imageAlert">
      <div className="alert_img_wrapper">
        <img src={ImgSrc} alt="Selected" />
        <button className="close_button" onClick={() => setOpenAlert(false)}>
          <IoClose />
        </button>
      </div>
    </div>
  );
};

export default ImageAlert;
