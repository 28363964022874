import React from "react";
import "./SimilarProducts.scss";
import { assets } from "../../assets/assets";
import { MdOutlineArrowForwardIos } from "react-icons/md" ;
import Slider from "react-slick";
import { PrevArrow, NextArrow } from "./CustomArrow" ;
import { Link, useNavigate } from "react-router-dom";


const SimilarProducts = ({categoryProducts = []}) => {

  const navigate = useNavigate();


  const handleNavigateToSingle = (product)=> {
    navigate("/single", {
       state:{ product : product, categoryProducts: categoryProducts }
      });
  };
  
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    // arrows: false, 
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <div className="similar_product">
      <h5>Similar Products</h5>
      
      <div className="similar_list">
      <Slider {...settings}>
           {categoryProducts.map((product)=> (
        <div>
      
        <div className="similar_item">
          
          <div className="img_div">

          <img src={assets[product.img]} alt="" />

          </div>
          
          <h6> {product.name} </h6>
          <p>
            {product.shortDesc}
          </p>
         
          <button onClick={() => handleNavigateToSingle(product)}>
            Read More <MdOutlineArrowForwardIos />
          </button>
        </div>
        </div>
          ))}
         </Slider> 
      </div>
       
    </div>
  );
};

export default SimilarProducts;
