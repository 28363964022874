import React, { useEffect } from "react";
import ProductBanner from "../components/ProductBanner/ProductBanner";
import ViewProductList from "../components/ViewProductList/ViewProductList";
import Footer from "../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import Adhesive from "../components/ViewProductList/Adhesive/Adhesive";

const Product = () => {

  const { state } = useLocation();
  const category = state?.category;

  // console.log(category, "tis is product prop...........")

  // const location = useLocation();

  // console.log(location, "THis is locaiton /...........")

  useEffect(() => {

    window.scrollTo(0,0);

   
  
  },[])

  return (
    <div>
      <ProductBanner category={category} />

      { category === "Adhesives" ? (
        <Adhesive category={category} />
       ) : (
        <ViewProductList category={category} />
       )}

      <Footer />
    </div>
  );

};

export default Product;
