import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import "./SimilarProducts.scss";

export const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowleft_slide" onClick={onClick}>
      <FaArrowRight size={30} color="orange" />
    </div>
  );
};

export const NextArrow = (props) => {
  const {  onClick } = props;
  return (
    <div className="arrowright_slide" onClick={onClick}>
      <FaArrowLeft size={30} color="orange" />
    </div>
  );
};
