import React, { useEffect } from "react";
import "./Footer.scss";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { assets } from "../../assets/assets";
import AOS from "aos";
import "aos/dist/aos.css";
import { scroller } from "react-scroll";

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: false,
    });
  }, []);

  const handleNavigation = (to) => {
    scroller.scrollTo(to, {
      smooth: true,
      duration: 500,
      offset: -70,
    });
  };

  return (
    <div className="footer">
      <footer data-aos="fade-right">
        <ul>
          <h6> About Us </h6>
          <li onClick={() => handleNavigation("productCategory")}>Products</li>
          <li onClick={() => handleNavigation("services")}>Services</li>
          <li>Achievements</li>
          <li>Specialties</li>
          <li onClick={() => handleNavigation("contactUs")}>Contact Us</li>
        </ul>
        <ul>
          <h6>Industries We Serve</h6>
          <li>Automobile Industry</li>
          <li>Government Organizations</li>
          <li>Engineering Firms</li>
          <li>Pharmaceutical Industry</li>
          <li>Oil and Gas Industry</li>
        </ul>
        {/* <ul>
          <h6>Contact Us</h6>
          <li>
            1/243, Industrial Development Area,Erumathala Post, Alwaye –
            683105.Ernakulam Dist, Kerala, India.
          </li>
          <li>
            <span>Phone:</span> (91) (484) 2839627, 2672654
          </li>
          <li>
            <span>Tele/Fax:</span> (91)(484) – 2672655
          </li>
          <li>
            <span>Mobile:</span> 9447024924
          </li>
          <li>
            <span>Email:</span> indel@rediffmail.com
          </li>

          <li className="connect">Connect with us</li>
          <li className="social">
            <FaInstagram />
            <FaFacebook />
            <FaYoutube />

            <FaWhatsapp />
          </li>
        </ul> */}

        <ul>
          <h6>Contact Us</h6>
          <li>
            1/243, Industrial Development Area,Erumathala Post, Alwaye –
            683105.Ernakulam Dist, Kerala, India.
          </li>
          {/* <li>
            <span>Phone:</span> (91) (484) 2839627, 2672654
          </li> */}
          <li>
            <span>Lan:</span> 0484-2001594
          </li>
          <li>
            <span>Mobile:</span> 9447024924, 9747280006
          </li>
          <li>
            <span>Email:</span> indel@rediffmail.com <br />
            industrialelastomers@gmail.com
          </li>

          <li className="connect">Connect with us</li>
          <li className="social">
            <FaInstagram />
            <FaFacebook />
            <FaYoutube />
            {/* <FaLinkedin />
            <FaXTwitter /> */}
            <FaWhatsapp />
          </li>
        </ul>

        <ul className="logo_contianer">
          <li className="iso_logo">
            {/* <div className="img_text"> */}

            <div className="iso_img">
              <img src={assets.IsoLogo} alt="" />
            </div>
            <h3>
              AN ISO 9001-2015 <span>CIRTIFIED COMPANY</span>{" "}
            </h3>
            {/* <span>CIRTIFIED COMPANY</span> */}
            {/* </div> */}
          </li>
        </ul>
      </footer>
      <div className="footer_bottom">
        <p>© Industrial Elastomers 2024 . All Rights Reserved.</p>
        <p>
          <span>Developed By</span>
          <a
            href="https://www.spinecodes.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={assets.Spine} alt="Spinecodes" />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
