


// import React, { useEffect, useState } from 'react';
// import './Gallery.scss';
// import { assets } from '../../assets/assets';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const Gallery = () => {
//   const [breakpoint, setBreakPoint] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => setBreakPoint(window.innerWidth);
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const isMobile = breakpoint <= 1000;

//   const sliderSettings = {
//     dots: false,
//     infinite: true,
//     speed: 3000,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     vertical: true,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     cssEase: "linear",arrows: false,
//     pauseOnHover: false,
//   };
//   const slidSettings = {
//     dots: false,
//     infinite: true,
//     speed: 3000,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     horizontal: true,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     cssEase: "linear",arrows: false,
//     pauseOnHover: false,
//     responsive: [
//       {
//         breakpoint: 750,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ],
//   };

//   return (
//     <div className='gallery_container'>
//       <h6>Media</h6>
//       {isMobile ? (
//         <Slider {...slidSettings} className='gallery_item_container'>
//           <div className='single_slide'>
//             <img src={assets.gallery_sub_1_img_1} alt='' />
//           </div>
//           <div  className='single_slide'>
//             <img src={assets.gallery_sub_1_img_2} alt='' />
//           </div>
//           <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_3} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_4} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_5} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_6} alt='' />
//               </div>


//           <div className='single_slide'>
//             <img src={assets.gallery_sub_2_img_1} alt='' />
//           </div>
//           <div className='single_slide'>
//             <img src={assets.gallery_sub_2_img_2} alt='' />
//           </div>
//           <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_3} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_4} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_5} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_6} alt='' />
//               </div>



//           <div className='single_slide'>
//             <img src={assets.gallery_sub_3_img_1} alt='' />
//           </div>
//           <div className='single_slide'>
//             <img src={assets.gallery_sub_3_img_2} alt='' />
//           </div>
//           <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_3} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_4} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_5} alt='' />
//               </div>
//               {/* <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_6} alt='' />
//               </div> */}
//         </Slider>
//       ) : (
//         <div className='gallery_item_container'>
//           <div className='gallery_item_container_sub'>

//             <Slider {...sliderSettings}>

//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_1} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_2} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_3} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_4} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_5} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_6} alt='' />
//               </div>
              
             
//             </Slider>
//           </div>
//           <div className='gallery_item_container_sub_middle'>
//             <Slider {...sliderSettings} className='secondSlide'>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_1} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_2} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_3} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_4} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_5} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_6} alt='' />
//               </div>
             
//             </Slider>
//           </div>
//           <div className='gallery_item_container_sub_3'>
//             <Slider {...sliderSettings}>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_1} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_2} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_3} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_4} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_5} alt='' />
//               </div>
//               {/* <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_6} alt='' />
//               </div> */}

             
//             </Slider>
            
//           </div>

//         </div>
//       )}
//     </div>
//   );
// };

// export default Gallery;



// import React, { useEffect, useState } from 'react';
// import './Gallery.scss';
// import { assets } from '../../assets/assets';
// import Aos from 'aos';

// const Gallery = () => {




//   useEffect(() => {

//     Aos.init({
//       duration: 800,
//       easing: "ease-in-out",
//       once: false,
//     });
  
//   }, [])

//   return (
//     <div className='gallery_container'>
//       <h6 data-aos="fade-up" >Media</h6>
      
//       <div className='collage_container'>

//         <div className="collage_section_1">

//           <div className="collage_section_1_half_1">
            

//           {/* <div  className="colum_vertical">
//             <div data-aos="fade-down" className="colum_vertical_img_div">
//             <img src={assets.gallery_sub_1_img_1} alt='' />
//             </div>
//             <div data-aos="fade-down" className="colum_vertical_img_div">
//             <img src={assets.gallery_sub_1_img_3} alt='' />
//               </div>
//           </div>

//           <div data-aos="fade-down" className="colum_Big_img_vertical">

       

//           <div  className="colum_vertical">
            
//             <div data-aos="fade-down" className="colum_vertical_img_div">
//             <img src={assets.gallery_sub_1_img_1} alt='' />
//             </div>
//             <div data-aos="fade-down" className="colum_vertical_img_div">
//             <img src={assets.gallery_sub_1_img_3} alt='' />
//               </div>
//           </div>


//           </div> */}

// <div className="collage_section_2_half_2_subs">

// <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
// <img src={assets.gallery_sub_1_img_1} alt='' />
// </div>
// <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
// <img src={assets.gallery_sub_1_img_3} alt='' />
//   </div>


// </div>

// <div className="collage_section_2_half_2_subs">

// <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
// <img src={assets.gallery_sub_1_img_1} alt='' />
// </div>
// <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
// <img src={assets.gallery_sub_1_img_3} alt='' />
//   </div>


// </div>

        




//           </div>

//           <div  className="collage_section_1_half_2">

//             <div data-aos="fade-up" className="collage_section_1_half_2_img_main">

//             <img src={assets.gallery_sub_2_img_1} alt='' />
              
//             </div>



//           </div>


//         </div>

//         <div  className="collage_section_2">
            
//         <div  className="collage_section_2_half_1">

//           <div data-aos="fade-down" className="collage_section_2_half_1_main_img">
//           <img src={assets.gallery_sub_2_img_2} alt='' />
//           </div>




//           </div>

//           <div  className="collage_section_2_half_2">

//               <div className="collage_section_2_half_2_subs">

//                 <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
//                 <img src={assets.gallery_sub_2_img_5} alt='' />
//                 </div>
//                 <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
//                 <img src={assets.gallery_sub_2_img_3} alt='' />
//                   </div>


//               </div>

//               <div className="collage_section_2_half_2_subs">

//                 <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
//                 <img src={assets.gallery_sub_2_img_4} alt='' />
//                 </div>
//                 <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
//                 <img src={assets.gallery_sub_3_img_1} alt='' />
//                   </div>


//               </div>




//           </div>
          
//         </div>
        
     
//       </div>
//     </div>
//   );
// };

// export default Gallery;





// import React, { useEffect, useState } from 'react';
// import './Gallery.scss';
// import { assets } from '../../assets/assets';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const Gallery = () => {
//   const [breakpoint, setBreakPoint] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => setBreakPoint(window.innerWidth);
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const isMobile = breakpoint <= 1000;

//   const sliderSettings = {
//     dots: false,
//     infinite: true,
//     speed: 3000,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     vertical: true,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     cssEase: "linear",arrows: false,
//     pauseOnHover: false,
//   };
//   const slidSettings = {
//     dots: false,
//     infinite: true,
//     speed: 3000,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     horizontal: true,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     cssEase: "linear",arrows: false,
//     pauseOnHover: false,
//     responsive: [
//       {
//         breakpoint: 750,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ],
//   };

//   return (
//     <div className='gallery_container'>
//       <h6>Media</h6>
//       {isMobile ? (
//         <Slider {...slidSettings} className='gallery_item_container'>
//           <div className='single_slide'>
//             <img src={assets.gallery_sub_1_img_1} alt='' />
//           </div>
//           <div  className='single_slide'>
//             <img src={assets.gallery_sub_1_img_2} alt='' />
//           </div>
//           <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_3} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_4} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_5} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_1_img_6} alt='' />
//               </div>


//           <div className='single_slide'>
//             <img src={assets.gallery_sub_2_img_1} alt='' />
//           </div>
//           <div className='single_slide'>
//             <img src={assets.gallery_sub_2_img_2} alt='' />
//           </div>
//           <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_3} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_4} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_5} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_2_img_6} alt='' />
//               </div>



//           <div className='single_slide'>
//             <img src={assets.gallery_sub_3_img_1} alt='' />
//           </div>
//           <div className='single_slide'>
//             <img src={assets.gallery_sub_3_img_2} alt='' />
//           </div>
//           <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_3} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_4} alt='' />
//               </div>
//               <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_5} alt='' />
//               </div>
//               {/* <div className='single_slide'>
//                 <img src={assets.gallery_sub_3_img_6} alt='' />
//               </div> */}
//         </Slider>
//       ) : (
//         <div className='gallery_item_container'>
//           <div className='gallery_item_container_sub'>

//             <Slider {...sliderSettings}>

//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_1} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_2} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_3} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_4} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_5} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_1_img_6} alt='' />
//               </div>
              
             
//             </Slider>
//           </div>
//           <div className='gallery_item_container_sub_middle'>
//             <Slider {...sliderSettings} className='secondSlide'>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_1} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_2} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_3} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_4} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_5} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_2_img_6} alt='' />
//               </div>
             
//             </Slider>
//           </div>
//           <div className='gallery_item_container_sub_3'>
//             <Slider {...sliderSettings}>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_1} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_2} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_3} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_4} alt='' />
//               </div>
//               <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_5} alt='' />
//               </div>
//               {/* <div className='sliderone'>
//                 <img src={assets.gallery_sub_3_img_6} alt='' />
//               </div> */}

             
//             </Slider>
            
//           </div>

//         </div>
//       )}
//     </div>
//   );
// };

// export default Gallery;



import React, { useEffect, useState } from 'react';
import './Gallery.scss';
import { assets } from '../../assets/assets';
import Aos from 'aos';

const Gallery = () => {




  useEffect(() => {

    Aos.init({
      duration: 800,
      easing: "ease-in-out",
      once: false,
    });
  
  }, [])

  return (
    <div className='gallery_container'>
      <h6 data-aos="fade-up" >Media</h6>
      <div className='collage_container'>

        <div className="collage_section_1">

          <div className="collage_section_1_half_1">

          <div  className="colum_vertical">
            <div data-aos="fade-down" className="colum_vertical_img_div">
            <div className="img_wrapper__">
            <img src={assets.gallery_sub_1_img_1} alt='' />
            </div>
            </div>
            <div data-aos="fade-down" className="colum_vertical_img_div">
            <div className="img_wrapper__">
            <img src={assets.adesiveMain} alt='' />
            </div>
              </div>
          </div>

          <div  className="colum_vertical">
            <div data-aos="fade-down" className="colum_vertical_img_div">
            <div className="img_wrapper__">
            <img src={assets.rubberToMetalRubberRollerse} alt='' />
            </div>
            </div>
            <div data-aos="fade-down" className="colum_vertical_img_div">
            <div className="img_wrapper__">
            <img src={assets.gallery_sub_1_img_3} alt='' />
            </div>
              </div>
          </div>


          </div>

          <div className="collage_section_1_half_1">

<div  className="colum_vertical">

  <div data-aos="fade-down" className="colum_vertical_img_div">

  <div className="img_wrapper__">
  <img src={assets.gallery_sub_2_img_1} alt='' />
  </div>
  </div>






  <div data-aos="fade-down" className="colum_vertical_img_div">
  <div className="img_wrapper__">
  <img src={assets.gallery_sub_2_img_2} alt='' />
  </div>
    </div>
</div>

<div  className="colum_vertical">
  <div data-aos="fade-down" className="colum_vertical_img_div">
  <div className="img_wrapper__">
  <img src={assets.gallery_sub_2_img_5} alt='' />
  </div>
  </div>
  <div data-aos="fade-down" className="colum_vertical_img_div">
  <div className="img_wrapper__">
  <img src={assets.gallery_sub_2_img_3} alt='' />
  </div>
    </div>
</div>


</div>


          {/* <div  className="collage_section_1_half_2">

            <div data-aos="fade-up" className="collage_section_1_half_2_img_main">

            <img src={assets.gallery_sub_2_img_1} alt='' />
              
            </div>



          </div> */}


        </div>

        <div  className="collage_section_2">
            
        <div  className="collage_section_2_half_1">

          {/* ------------------------------------------------------ connected  */}

<div className="collage_section_2_half_2_subs">

<div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
<div className="img_wrapper__">
<img src={assets.Knee_guard_ad} alt='' />
</div>
</div>
  
<div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">

<div className="img_wrapper__">
<img src={assets.gallery_sub_3_img_1} alt='' />

  </div>
  
  </div>


</div>

<div className="collage_section_2_half_2_subs">

<div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
<div className="img_wrapper__">
<img src={assets.gallery_sub_1_img_5} alt='' />
</div>
</div>
<div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
<div className="img_wrapper__">
<img src={assets.gallery_sub_1_img_4} alt='' />
</div>
  </div>


</div>

          </div>
          <div  className="collage_section_2_half_2">

              <div className="collage_section_2_half_2_subs">

                <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
                <div className="img_wrapper__">
                <img src={assets.gallery_sub_2_img_4} alt='' />
                </div>
                </div>
                <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
                <div className="img_wrapper__">
                <img src={assets.gallery_sub_3_img_5} alt='' />
                </div>
                  </div>


              </div>

              <div className="collage_section_2_half_2_subs">

                <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
                <div className="img_wrapper__">
                <img src={assets.gallery_sub_3_img_2} alt='' />
                </div>
                </div>
                <div data-aos="fade-up" className="collage_section_2_half_2_subs_img_div">
                <div className="img_wrapper__">
                <img src={assets.gallery_sub_3_img_3} alt='' />
                </div>
                  </div>
              </div>
          </div>

             {/* ------------------------------------------------------ connected  */}
          
        </div>
        
     
      </div>
    </div>
  );
};

export default Gallery;



