import Logo from "./images/logo.png";
import Tyre from "./images/about_img.png";
import showRoom from "./images/store.png";
import Ring_img_3 from "./images/ring.png";
import Autom_mob_img from "./images/automobileIMg.png";
import MouldedComponentsIMg from "./images/molded-rubber-products 3 (1).png";
import RubbertomentalBondedIMg from "./images/rubber_to_metal IMg.png";
import ORingsandGasketsIMg from "./images/oring and gas staek.png";
import ExtrudedComponentsIMg from "./images/extruded_main_img.png";
import PharmaceuticalRubberProducts from "./images/Pharmaceutical Rubber Products img.png";
import AdhesivesIMg from "./images/adhasives.png";
import arrowForward from "./images/chevron_forward.png";
import Bush from "./images/Bush.png";import Spine from "./images/spineLogo.svg";
import interQualityImg from "./images/inde_quality_img.png";
import CustomRubberMoldingImg from "./images/Custom Rubber Molding.png";
import RubbertoMetalBondedComponentsImg from "./images/Rubber-to-Metal Bonded Components.png";
import ExtrudedRubberComponentsIMg from "./images/Extruded Rubber Components.png";
import CustomFormulationsImg from "./images/Custom Formulations.png";
import IndustryApplicationsImg from "./images/Industry Applications.png";
import ClientSupporrIndCustomSolutionsImg from "./images/Client Support and Custom Solutions.png";
import whyChoseCustomersImg from "./images/Why Choose Industrial Elastomers.png";
import gallery_sub_1_img_1 from "./images/gallery_sub_1img 1.png";
import gallery_sub_1_img_2 from "./images/gallery_sub_1 img 2.png";
import gallery_sub_2_img_1 from "./images/gallery_sub_2 img 1.png";
import gallery_sub_2_img_2 from "./images/gallery sub 2 img 2.png";
import gallery_sub_3_img_1 from "./images/gallery sub 3 img 2.png";
import gallery_sub_3_img_2 from "./images/gallery sub 3 img 3.png";
import slidImg from "./images/slid_img_3.png";
import menuLogo from "./images/mnu.svg";
import menuClose from "./images/menu_close.svg";
import gallery_sub_1_img_3 from "./images/gallery_sub_1_img_3.png"
import gallery_sub_1_img_4 from "./images/gallery_sub_1_img_4.png"
import gallery_sub_2_img_3 from "./images/gallery_sub_2_img_3.png"
import gallery_sub_2_img_4 from "./images/gallery_sub_2_img_4.png"
import gallery_sub_3_img_3 from "./images/gallery_sub_3_img_3.png"
import gallery_sub_3_img_4 from "./images/gallery_sub_3_img_4.png"
import gallery_sub_1_img_5 from "./images/gallery_sub_1_img_5.png"
import gallery_sub_1_img_6 from "./images/gallery_sub_1_img_6.png"
import gallery_sub_2_img_5 from "./images/gallery_sub_2_img_5.png"
import gallery_sub_2_img_6 from "./images/gallery_sub_2_img_6.png"
import gallery_sub_3_img_5 from "./images/gallery_sub_3_img_5.png"
import gallery_sub_3_img_6 from "./images/gallery_sub_3_img_6.png"
import gasket_imag_2 from "./images/gasket_2.png"
import anti_vibration_bushes_mutiple from "./images/anti_vibration_bushes_multiple.png"


import catheterValve from "./images/catheter valve.png"
import Hernia_aid_rubber_pads from "./images/Hernia aid rubber pads.png"
import Knee_guard_ad from "./images/Knee guard pad.png"
import rubberToMetalRubberRollerse from "./images/rubberToMetalRubberRollerse.png"
import RubberToMetalSeals from "./images/rubberTOmeatlSEals.png"
import gasket from "./images/gasket.png"
import Oring from "./images/Oring.png"
import pressureCookerGasket from "./images/pressureCookerGasket.jpg"
import Nf1200 from "./images/Nf12000.png"
import IsoLogo from "./images/iso_logo.png"
import ORingComponentMainIMg from "./images/orig_main_component_img-removebg-preview.png"

import Grommet from "./images/gallery_sub_2_img_3.png"

import AntiVibrationImg from "./images/Bellows.png"

import bellows from "./images/Grommet.png"

import Rubber_squeegees from "./images/rubber_squeegees.png"

import Extrude_item_img from "./images/extruded component.png"

import pharmaMainImg from "./images/pharma main img.png"

import adesiveMain from "./images/adhasiveMain.png"

import GroupAdhesive500 from "./images/NF 5000.png"


import GroupAdhesive12000 from "./images/NF 12000.png"

import catelogue_1 from "./images/catelogue.jpg"

import achivementsChandrayan from "./images/chandrayan.webp"

import bellows_img_latest from "./images/bellows_img_latest.png"

import spaceShip2 from "./images/spaceship2.jpg"


import engineer_slid_2 from "./images/engineer2slide.webp"





export const assets = {

  Logo,
  Tyre,
  showRoom,
  Ring_img_3,
  Autom_mob_img,
  MouldedComponentsIMg,
  RubbertomentalBondedIMg,
  ORingsandGasketsIMg,
  ExtrudedComponentsIMg,
  PharmaceuticalRubberProducts,
  AdhesivesIMg,
  arrowForward,
  Bush,
  Spine,
  interQualityImg,
  CustomRubberMoldingImg,
  RubbertoMetalBondedComponentsImg,
  ExtrudedRubberComponentsIMg,
  CustomFormulationsImg,
  IndustryApplicationsImg,
  ClientSupporrIndCustomSolutionsImg,
  whyChoseCustomersImg,
  gallery_sub_1_img_1,
  gallery_sub_1_img_2,
  gallery_sub_2_img_1,
  gallery_sub_2_img_2,
  gallery_sub_3_img_1,
  gallery_sub_3_img_2,
  slidImg,
  menuLogo,
  menuClose,
  gallery_sub_1_img_3,
  gallery_sub_1_img_4,
  gallery_sub_1_img_5,
  gallery_sub_1_img_6,
  gallery_sub_2_img_3,
  gallery_sub_2_img_4,
  gallery_sub_2_img_5,
  gallery_sub_2_img_6,
  gallery_sub_3_img_3,
  gallery_sub_3_img_4,
  gallery_sub_3_img_5,
  gallery_sub_3_img_6,
  Grommet,
  bellows,
  catheterValve,
  Hernia_aid_rubber_pads,
  Knee_guard_ad,
  rubberToMetalRubberRollerse,
  RubberToMetalSeals,
  gasket
  ,Oring,
  pressureCookerGasket,
  Nf1200,
  IsoLogo,
  ORingComponentMainIMg,
  AntiVibrationImg,
  Rubber_squeegees,
  Extrude_item_img,
  pharmaMainImg,
  adesiveMain,
  GroupAdhesive500,
  GroupAdhesive12000,
  gasket_imag_2,
  anti_vibration_bushes_mutiple,
  catelogue_1,
  achivementsChandrayan,
  bellows_img_latest,
  spaceShip2,
  engineer_slid_2
  

}