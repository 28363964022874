import React from "react";
import "./ProductBanner.scss";
import Header from "../Header/Header";

const ProductBanner = ({ category }) => {
  return (
    <div className="productbanner">
      <Header />
      <div className="product_banner_contet">
        <div className="product_content">
          <h4>{category} </h4>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default ProductBanner;
