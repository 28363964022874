import React, { useEffect, useState } from "react";
import Spotlight from "../components/Spotlight/Spotlight";
import About from "../components/About/About";
import Services from "../components/services/Services";
import CompanyDetails from "../components/CompanyDetails/CompanyDetails";
import ProductCatogory from "../components/ProductCatogory/ProductCatogory";
import IntelQualitySec from "../components/IntelQuantitySection/IntelQualitySec";
import Gallery from "../components/Gallery/Gallery";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import "./Home.scss";
import { IoIosArrowUp } from "react-icons/io";
import Catalogue from "../components/Catalogue/Catalogue";

const Home = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [borderOffset, setBorderOffset] = useState(283);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 283; // 283 is the circumference of the circle
    const borderOffset = 283 - scrollPercentage;

    setBorderOffset(borderOffset);

    setShowScrollTop(scrollTop > clientHeight);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScrollDebounced = () => {
      handleScroll();
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(() => {
        document.querySelector(".scroll_top").classList.remove("active");
      }, 100);
    };

    window.addEventListener("scroll", handleScrollDebounced);
    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
      clearTimeout(window.scrollTimeout);
    };
  }, []);

  return (
    <div className="home">
      <Header />
      <section id="home">
        <Spotlight />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="productCategory">
        <ProductCatogory />
      </section>

      {/* <section>
        <Catalogue />
      </section> */}

      {/* <section>
        <IntelQualitySec />
      </section> */}
      {/* <section>
        <CompanyDetails />
      </section> */}

      <section id="services">
        <Services />
      </section>

      <section id="gallery">
        <Gallery />
      </section>
      <section id="contactUs">
        <ContactUs />
      </section>
      <Footer />

      <div
        className={`scroll_top ${showScrollTop ? "show" : ""}`}
        onClick={scrollToTop}
      >
        <svg className="progress-circle" viewBox="0 0 100 100">
          <circle
            className="progress-background"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <circle
            className="progress-circle-bar"
            cx="50"
            cy="50"
            r="45"
            style={{ strokeDashoffset: borderOffset }}
          ></circle>
        </svg>
        <IoIosArrowUp /> {/* Use the icon here */}
      </div>
    </div>
  );
};

export default Home;
