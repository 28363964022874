// import React, { useEffect } from "react";
// import "./Catalogue.scss";
// import { assets } from "../../assets/assets";

// const Catalogue = () => {
//   return (
//     <div className="catalogue">
//       <h5>Catalogue</h5>
//       <div className="catelogue_items">
//         <div className="Items_wrapper">
//           <div className="imgWrapper">
//             <img src={assets.catelogue_1} alt="" />
//           </div>
//           <a download="catelogue" href="/Brochure_Adhesive.pdf">
//             <button> Download </button>
//           </a>
//         </div>

//         {/* <div className="Items_wrapper">
//         <div className="imgWrapper">
//         <img src={assets.catelogue_1} alt="" />
//         </div>
//         <a download="catelogue" href="/Brochure_Adhesive.pdf">
//         <button> Download </button>
//         </a>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default Catalogue;

import React from "react";
import "./Catalogue.scss";
import { assets } from "../../assets/assets";

const Catalogue = ({ onClose }) => {
  return (
    <>
      <div className="catalogue-overlay" onClick={onClose}></div>
      <div className="catalogue">
        <h5>Catalogue</h5>
        <button onClick={onClose} className="close-button">
          X
        </button>
        <div className="catelogue_items">
          <div className="Items_wrapper">
            <div className="imgWrapper">
              <img src={assets.catelogue_1} alt="" />
            </div>
            <a download="catelogue" href="/Brochure_Adhesive.pdf">
              <button> Download </button>
            </a>
          </div>
          <div className="Items_wrapper">
            <div className="imgWrapper">
              <img src={assets.catelogue_1} alt="" />
            </div>
            <a download="catelogue" href="/Brochure_Adhesive.pdf">
              <button> Download </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalogue;
