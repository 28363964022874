// import { useState, useEffect } from "react";
// import { animateScroll as scroll, scroller } from "react-scroll";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import "./Header.scss";
// import { assets } from "../../assets/assets";
// import { IoIosArrowRoundForward } from "react-icons/io";

// const Header = () => {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [breakpoint, setBreakPoint] = useState(window.innerWidth);
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [mobileMenu, setMobileMenu] = useState(window.innerWidth <= 920);
//   const [activeSection, setActiveSection] = useState("home");
//   const location = useLocation();
//   const navigate = useNavigate();

//   const currentPath = location.pathname;

//   console.log(currentPath, "current path form header ........");

//   useEffect(() => {
//     const handleResize = () => {
//       setBreakPoint(window.innerWidth);
//       setMobileMenu(window.innerWidth <= 920);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const openMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         currentPath === "/product" ||
//         currentPath === "/single" ||
//         currentPath === "Oring"
//       ) {
//         setIsScrolled(window.scrollY > 300);
//       } else if (currentPath === "/") {
//         setIsScrolled(window.scrollY > 550);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const handleNavigation = (to) => {
//     setActiveSection(to);

//     if (location.pathname === "/") {
//       scroller.scrollTo(to, {
//         smooth: true,
//         duration: 500,
//         offset: -70, // Adjust this offset to your needs
//       });
//     } else {
//       navigate("/");
//       setTimeout(() => {
//         scroller.scrollTo(to, {
//           smooth: true,
//           duration: 500,
//           offset: -70, // Adjust this offset to your needs
//         });
//       }, 100); // Slight delay to ensure the scroll happens after navigation
//     }
//     if (mobileMenu) openMenu(); // Close menu on mobile after click
//   };

//   return (
//     <div>
//       <header className={isScrolled ? "scrolled" : ""}>
//         <h1>
//           <img src={assets.Logo} alt="" />
//         </h1>

//         {mobileMenu ? (
//           <>
//             <img onClick={openMenu} src={assets.menuLogo} alt="Menu" />
//             {menuOpen && (
//               <ul className="mobile_nav">
//                 <div className="nav_head">
//                   <div className="logo_mobile">
//                     <img src={assets.Logo} alt="" />
//                   </div>
//                   <img
//                     className="closeSvg"
//                     onClick={openMenu}
//                     src={assets.menuClose}
//                     alt="Menu"
//                   />
//                 </div>
//                 <li
//                   className={activeSection === "home" ? "active" : ""}
//                   onClick={() => handleNavigation("home")}
//                 >
//                   Home <IoIosArrowRoundForward />
//                 </li>
//                 <li
//                   className={activeSection === "about" ? "active" : ""}
//                   onClick={() => handleNavigation("about")}
//                 >
//                   About Us <IoIosArrowRoundForward />
//                 </li>
//                 <li
//                   className={
//                     activeSection === "productCategory" ? "active" : ""
//                   }
//                   onClick={() => handleNavigation("productCategory")}
//                 >
//                   Products <IoIosArrowRoundForward />
//                 </li>
//                 <li
//                   className={activeSection === "services" ? "active" : ""}
//                   onClick={() => handleNavigation("services")}
//                 >
//                   Specializations <IoIosArrowRoundForward />
//                 </li>

//                 <Link to="/achievements">
//                   <li>
//                     Achievements <IoIosArrowRoundForward />
//                   </li>
//                 </Link>

//                 <li
//                   className={activeSection === "gallery" ? "active" : ""}
//                   onClick={() => handleNavigation("gallery")}
//                 >
//                   Media <IoIosArrowRoundForward />
//                 </li>
//                 <li
//                   className={activeSection === "contactUs" ? "active" : ""}
//                   onClick={() => handleNavigation("contactUs")}
//                 >
//                   Contact Us <IoIosArrowRoundForward />
//                 </li>
//               </ul>
//             )}
//           </>
//         ) : (
//           <ul className="nav_bar">
//             <li
//               className={activeSection === "home" ? "active" : ""}
//               onClick={() => handleNavigation("home")}
//             >
//               Home
//             </li>
//             <li
//               className={activeSection === "about" ? "active" : ""}
//               onClick={() => handleNavigation("about")}
//             >
//               About Us
//             </li>
//             <li
//               className={activeSection === "productCategory" ? "active" : ""}
//               onClick={() => handleNavigation("productCategory")}
//             >
//               Products
//             </li>
//             <li
//               className={activeSection === "services" ? "active" : ""}
//               onClick={() => handleNavigation("services")}
//             >
//               Specializations
//             </li>
//             <Link to="/achievements">
//               <li>Achievements</li>
//             </Link>
//             <li
//               className={activeSection === "gallery" ? "active" : ""}
//               onClick={() => handleNavigation("gallery")}
//             >
//               Media
//             </li>
//             <li
//               className={activeSection === "contactUs" ? "active" : ""}
//               onClick={() => handleNavigation("contactUs")}
//             >
//               Contact Us
//             </li>
//           </ul>
//         )}
//       </header>
//     </div>
//   );
// };

// export default Header;

import { useState, useEffect } from "react";
import { animateScroll as scroll, scroller } from "react-scroll";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import { assets } from "../../assets/assets";
import { IoIosArrowRoundForward } from "react-icons/io";
import Catalogue from "../Catalogue/Catalogue";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [breakpoint, setBreakPoint] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(window.innerWidth <= 920);
  const [activeSection, setActiveSection] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  console.log(currentPath, "current path ......");

  const [activeAchive, setActiveAchive] = useState("");
  const [catalog, setCatalog] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setBreakPoint(window.innerWidth);
      setMobileMenu(window.innerWidth <= 920);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        currentPath === "/product" ||
        currentPath === "/single" ||
        currentPath === "Oring"
      ) {
        setIsScrolled(window.scrollY > 300);
      } else if (currentPath === "/") {
        setIsScrolled(window.scrollY > 600);
      }

      // Update activeSection based on scroll position

      const sections = [
        "home",
        "about",
        "productCategory",
        "services",
        "gallery",
        "contactUs",
        "achievements",
      ];

      let currentSection = "home";

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element && window.scrollY >= element.offsetTop - 70) {
          currentSection = section;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPath]);

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (to) => {
    setActiveSection(to);

    if (to === "achievements") {
      setActiveAchive(to);
      navigate("/achievements");
      return;
    }

    if (location.pathname === "/") {
      scroller.scrollTo(to, {
        smooth: true,
        duration: 500,
        offset: -70, // Adjust this offset to your needs
      });
    } else {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(to, {
          smooth: true,
          duration: 500,
          offset: -70, // Adjust this offset to your needs
        });
      }, 100); // Slight delay to ensure the scroll happens after navigation
    }
    if (mobileMenu) openMenu(); // Close menu on mobile after click
  };
  const catalogeCome = () => {
    setCatalog(true);
  };
  const closeCatalog = () => {
    setCatalog(false);
  };

  return (
    <div>
      <header
        className={
          isScrolled || currentPath === "/achievements" ? "scrolled" : ""
        }
      >
        <h1>
          <img src={assets.Logo} alt="" />
        </h1>

        {mobileMenu ? (
          <>
            <img onClick={openMenu} src={assets.menuLogo} alt="Menu" 
          //   className={
          // currentPath === "/achievements" ? "ToOrange" : ""        }
           />
            {menuOpen && (
              <ul className="mobile_nav">
                <div className="nav_head">
                  <div className="logo_mobile">
                    <img src={assets.Logo} alt="" />
                  </div>
                  <img
                    className="closeSvg"
                    onClick={openMenu}
                    src={assets.menuClose}
                    alt="Menu"
                  />
                </div>
                <li
                  className={activeSection === "home" ? "active" : ""}
                  onClick={() => handleNavigation("home")}
                >
                  Home <IoIosArrowRoundForward />
                </li>
                <li
                  className={activeSection === "about" ? "active" : ""}
                  onClick={() => handleNavigation("about")}
                >
                  About Us <IoIosArrowRoundForward />
                </li>
                <li
                  className={
                    activeSection === "productCategory" ? "active" : ""
                  }
                  onClick={() => handleNavigation("productCategory")}
                >
                  Products <IoIosArrowRoundForward />
                </li>
                <li
                  className={activeSection === "services" ? "active" : ""}
                  onClick={() => handleNavigation("services")}
                >
                  Specializations <IoIosArrowRoundForward />
                </li>
                <Link to="/achievements" className="achiemnts">
                  <li
                    className={activeSection === "achievements" ? "active" : ""}
                  >
                    Achievements <IoIosArrowRoundForward />
                  </li>
                </Link>

                <li
                  className={activeSection === "gallery" ? "active" : ""}
                  onClick={() => handleNavigation("gallery")}
                >
                  Media <IoIosArrowRoundForward />
                </li>
                <li onClick={catalogeCome}>Catalogue  <IoIosArrowRoundForward /></li>
                <li
                  className={activeSection === "contactUs" ? "active" : ""}
                  onClick={() => handleNavigation("contactUs")}
                >
                  Contact Us <IoIosArrowRoundForward />
                </li>
              </ul>
            )}
          </>
        ) : (
          <ul className="nav_bar">
            <li
              className={activeSection === "home" ? "active" : ""}
              onClick={() => handleNavigation("home")}
            >
              Home
            </li>
            <li
              className={activeSection === "about" ? "active" : ""}
              onClick={() => handleNavigation("about")}
            >
              About Us
            </li>
            <li
              className={activeSection === "productCategory" ? "active" : ""}
              onClick={() => handleNavigation("productCategory")}
            >
              Products
            </li>
            <li
              className={activeSection === "services" ? "active" : ""}
              onClick={() => handleNavigation("services")}
            >
              Specializations
            </li>
            {/* <Link  to="/achievements"> */}
            <li
              className={activeAchive === "achievements" ? "active" : ""}
              onClick={() => handleNavigation("achievements")}
            >
              Achievements
            </li>
            {/* </Link> */}
            <li
              className={activeSection === "gallery" ? "active" : ""}
              onClick={() => handleNavigation("gallery")}
            >
              Media
            </li>
            <li onClick={catalogeCome}>Catalogue</li>
            <li
              className={activeSection === "contactUs" ? "active" : ""}
              onClick={() => handleNavigation("contactUs")}
            >
              Contact Us
            </li>
          </ul>
        )}
      </header>
      {catalog && <Catalogue onClose={closeCatalog} />}
    </div>
  );
};

export default Header;
