import React from "react";
import "./SingleProductBanner.scss";
import Header from "../Header/Header";

const SingleProductBanner = () => {
  return (
    <div className="productbanner">
      <Header />
      <div className="product_banner_contet">
        <div className="product_content">
          <h4>Automobile Components</h4>
          <p>
            Empowering industries with bespoke rubber solutions since 1997,
            Industrial Elastomers is your trusted partner for
            precision-engineered rubber components.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleProductBanner;
