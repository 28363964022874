import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import ProductBanner from "../../components/ProductBanner/ProductBanner";
import "./OringGasket.scss";
import { assets } from "../../assets/assets";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const OringGasket = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate("/productCatogory");
  };

  return (
    <>
      <ProductBanner category={"Oring and Gaskets"} />

      <div className="OringGasket">
        <div className="content_head">
          <h6 onClick={handleBack}>
            Product Category
            <span>
              <MdOutlineArrowForwardIos /> Oring and Gaskets
            </span>
          </h6>

          <h5>Explore Our Range of Aerospace-Quality O-Rings and Gaskets</h5>
          <p>
            Welcome to our O-ring and gasket section, where precision meets
            performance. Discover how our O-rings can meet your sealing needs
            across various industries. At INDUSTRIAL ELASTOMERS, we specialize
            in manufacturing O-rings that deliver exceptional sealing
            performance and reliability. With 20 years of experience, we ensure
            each O-ring and gasket meets stringent quality standards.
          </p>
        </div>

        <div className="sub_content">
          <div className="content_">
            <h3>Key Features</h3>
            <ul>
              <li>
                {" "}
                <span>Material Options:</span>Nitrile (NBR), EPDM, Silicone,
                Viton®, and more.
              </li>
              <li>
                {" "}
                <span>Custom Solutions:</span>Tailored to meet specific size and
                material requirements.
              </li>
              <li>
                {" "}
                <span>Quality Assurance:</span> Rigorous testing to ensure
                durability and performance.
              </li>
            </ul>
          </div>
          <div className="content_">
            <h3>Industries We Serve</h3>

            <p className='paraHead'>Our o-rings and are trusted in diverse industries:</p>

            <ul>
              <li>
                {" "}
                <span>Automotive:</span>Engine gaskets, exhaust system gaskets,
                transmission seals
              </li>
              <li>
                {" "}
                <span>Industrial:</span>Pump seals, valve seals, flange gaskets.
              </li>
              <li>
                {" "}
                <span>Oil and Gas:</span> Pipeline seals, wellhead gaskets,
                drilling equipment.
              </li>
              <li>
                {" "}
                <span>Aerospace:</span>Aircraft engine gaskets, hydraulic system
                seals.
              </li>
            </ul>
          </div>
        </div>

        <div className="First_image_section">
          <div className="image_container">
            <img src={assets.gallery_sub_2_img_2} alt="" />
          </div>

          <div className="image_container">
            <img src={assets.gasket} alt="" />
          </div>
          <div className="image_container removable">
            <img src={assets.gasket_imag_2} alt="" />
          </div>
        </div>

        <div className="pressure_cooker_section">
          <h3>Reliable and Sealing Solutions for Pressure Cookers</h3>

          <p>
            Pressure cooker gaskets play a critical role in maintaining the
            safety and efficiency of pressure cooking appliances. These gaskets
            create a secure seal between the lid and the pot, allowing pressure
            to build up inside and cooking food faster while retaining
            nutrients. Here’s why pressure cooker gaskets are essential:
          </p>

          <div className="image_text_container">
            <div className="text_container_">
              <h4>Key Features of Pressure Cooker Gaskets</h4>

              <ul>
                <li>
                  {" "}
                  <span>Material Composition:</span>Typically made from
                  food-grade synthetic rubber to withstand high temperatures
                  and pressure.
                </li>
                <li>
                  {" "}
                  <span>Sealing Performance:</span>Ensures a tight seal to
                  prevent steam leakage during cooking, maintaining pressure for
                  effective cooking results
                </li>
                <li>
                  {" "}
                  <span>Durability:</span> Designed to withstand repeated use
                  and exposure to high heat without deforming or losing sealing
                  effectiveness.
                </li>
                <li>
                  {" "}
                  <span>Compatibility:</span>Available in various sizes and
                  designs to fit different pressure cooker models and brands.
                </li>
              </ul>
            </div>

            <div className="img_container">
              <img src={assets.gallery_sub_1_img_4} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OringGasket;
