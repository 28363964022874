import React, { useEffect } from "react";
import SingleProductBanner from "../components/SingleProductBanner/SingleProductBanner";
import SingleProductDetails from "../components/SingleProductDetails/SingleProductDetails";
import SimilarProducts from "../components/SimilarProducts/SimilarProducts";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import { useLocation } from "react-router-dom";

const SingleProduct = () => {

  const location = useLocation();

  const product = location.state?.product;

  const categoryProducts = location.state?.categoryProducts ;


  console.log(categoryProducts, "catregory products lllllllllllllllllllllllllllllll")

  console.log(product, "this is produc from single product statellllllllllll")

  useEffect(() => {

    window.scrollTo(0,0);
  
  },[product])

  return (
    <div>
      <SingleProductBanner />
      <SingleProductDetails product={product} />
      <SimilarProducts categoryProducts={categoryProducts}  />
      <ContactUs/>
      <Footer />
    </div>
  );
};

export default SingleProduct;
